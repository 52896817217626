<template>
  <div class="page-box">
    <div class="videotop flex-between">
      <div class="videotop-left">
        <span class="white cursor flex-between">
          <i class="iconfont el-icon-s-unfold" @click="playvideo=!playvideo"></i
          ><el-button type="text"  @click="playvideo=!playvideo">目录</el-button>
        </span>
        <span class="videotit">{{ detail.courseName }}</span>
      </div>
      <span>
        <el-popover
          placement="bottom"
          width="300"
          trigger="hover"
          class="share"
        >
          <div>
            <el-divider>分享给朋友</el-divider>
            <el-row>
              <el-col :span="12">
                <div><img src="@/assets/images/onlinecourses/erweima.png" alt=""></div>
              </el-col>
              <el-col :span="12" style="line-height:100px;display:flex;">
                <div v-for="(i,index) in shareImgList" :key="index">
                  <img :src="i.img" alt="" style="cursor: pointer;padding-right:5px">
                </div>
              </el-col>
            </el-row>
          </div>
          <el-button type="text" icon="el-icon-share" slot="reference"
            >分享</el-button
          >
        </el-popover>
      </span>
    </div>
    <!-- 左侧目录弹框 -->
    <div class="playlist" v-if="playvideo">
        <div class="container1">
            <div class="course-name">
                <span>《</span>
                <span class="name">{{ detail.courseName }}</span>
                <span>》</span>
            </div>
            <ul class="chapters">
                <li v-for="(item) in chapterList" :key="item.chapterId">
                    <div class="sub-title">{{item.chapterName}}</div>
                    <div class="task-item task-info" v-for="(val,index) in item.subMap" :key="index" @click="videoClick(val.chapterName,val.resourceId)">
                        <i class="el-icon-video-camera"></i>
                        <div class="task-inner" :style="{color:(menuIndex == val.resourceId ? '#3ce4bc' :'')}">
                            <div class="task-name" >
                                <!-- <a href=""> -->
                                     {{val.chapterName}}
                                     <span class="task-suffix">({{val.classHours}}分钟)</span>
                                <!-- </a> -->
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <!-- 评论弹框 -->
    <div class="chat-ctn" v-if="comments">
        <div class="app-root">
            <div class="im-tab tab-wrap">
                <div class="im-tab-hd im-tab-hd--bottom tabcolr">
                    <a href="javascript:void(0);" class="active">讨论</a>
                </div>               
            </div>
            <el-divider></el-divider>
            <div class="app-main">
              <div class="main-ctn-wrap">
                <div class="chat-wrap">
                  <div class="chat-list">
                    <div class="chat-item chat-item-msg" v-for="(i,index) in newsList" :key="index">
                      <img :src="i.headPortrait" alt="">
                      <div class="chat-flex">
                        <p class="chat-tit"><span>{{i.userName}}</span></p>
                        <p>{{i.newsContent}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="text-editor">
                    <el-input type="textarea" v-model="newinput"></el-input>
                    <div class="text-right pdr">
                      <el-button type="primary" size="small" @click="sendClick">发送</el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
    <div class="study-body ml" :style="{right:(comments ? '300px' : '0')}">
        <div class="iconote">
            <!-- 右侧提问 -->
            <div class="iconotebag" @click="questionsClick">
                <i class="el-icon-s-opportunity"></i>
                <p>提问</p>
            </div>
            <!-- 提问的弹框 -->
            <div class="answer" v-if="questions">
                <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入问题"
                    v-model="textarea">
                </el-input>
                <p class="elowbut">
                    <el-button type="primary" size="small" @click="questionsSubmit">提交问题</el-button>
                    <el-button type="primary" size="small" plain @click="questions=false">取消</el-button>
                </p>
            </div>
            <!-- 右侧笔记 -->
            <div class="iconotebag" @click="noteClick">
                <i class="el-icon-edit-outline"></i>
                <p>笔记</p>
            </div>
            <!-- 笔记弹框 -->
            <div class="answer editornote" v-if="note">
                <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入问题"
                    v-model="textareanote">
                </el-input>
                <p class="elowbut">
                    <el-button type="primary" size="small" @click="noteSubmit">提交笔记</el-button>
                    <el-button type="primary" size="small" plain @click="note=false">取消</el-button>
                </p>
            </div>
            <!-- 评论 -->
            <div class="iconotebag" @click="commentsClick">
                <i class="el-icon-edit-outline"></i>
                <p>评论</p>
            </div>
        </div>
    </div>
    <div class="w_auto">
      <div class="page-b" v-for="(i, index) in palyList" :key="index">
        <video
          class="video"
          controls
          autoplay
          oncontextmenu="window.event.returnValue=false" 
        >
          <source :src="videoList.resourceUrl" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      token: localStorage.getItem("pc_token"),
      value: JSON.parse(localStorage.getItem("pc_data")),
      playvideo: false,
      questions:false,
      note:false,
      drawer: false,
      comments:false,
      shareImgList:[
        {img:require('@/assets/images/onlinecourses/weibo.png')},
        {img:require('@/assets/images/onlinecourses/kongjian.png')},
        {img:require('@/assets/images/onlinecourses/dou.png')},
        {img:require('@/assets/images/onlinecourses/tie.png')},
      ],
      detail: [],
      chapterList: [],
      palyList: [],
      videoList: [],
      newsList:[],
      textarea: '',
      textareanote:'',
      newinput: '',
      menuIndex: 0,
    };
  },
  mounted() {
    this.getCourseDetailApi();
    this.getFrontCourseChapterApi();
    // this.getMyCourseResource();
    this.getTrainCourseNewsList()
    this.$nextTick(() => {
       
        // 禁用右键
        // document.oncontextmenu = new Function("event.returnValue=false");
        // 禁用选择
        // document.onselectstart = new Function("event.returnValue=false");
      });
  },
  methods: {
    //   提问弹框
    questionsClick(){
        this.questions =! this.questions
    },
    // 提交问题确定按钮
    questionsSubmit(){
        this.saveTrainCourseAskQuestion()
    },
    // 提交问题确定接口
    saveTrainCourseAskQuestion(){
        this.$api.saveTrainCourseAskQuestion({
          courseId: this.$route.query.courseId,
          askQuestion: this.textarea,
          token: this.token,
        }).then(res => {
            if(res.code == 10000){
                this.questions = false
                this.$message.success("提交成功");
                this.textarea = ''
            }
        })
    },
    // 笔记弹框
    noteClick(){
        this.note =! this.note
    },
    // 提交笔记确定按钮
    noteSubmit(){
        this.saveTrainCourseNote()
    },
    // 笔记接口
    saveTrainCourseNote(){
        this.$api.saveTrainCourseNote({
          courseId: this.$route.query.courseId,
          noteContent: this.textareanote,
          resourceId: this.$route.query.resourceId,
          token: this.token,
        }).then(res => {
            if(res.code == 10000){
                this.note = false
                this.$message.success("提交成功");
                this.textareanote = ''
            }
        })
    },
    // 评论按钮
    commentsClick(){
      this.comments =! this.comments    
      
    },
    // 查询消息列表
    getTrainCourseNewsList(){
      this.$api.getTrainCourseNewsList({    
        courseName: this.detail.courseName,
        typeOne: this.detail.typeOne, 
        typeTwo: this.detail.typeTwo,
        typeThree: this.detail.typeThree,
        userName: this.value.userName,
        mobilePhone: this.value.mobilePhone,
        courseId: this.$route.query.courseId,
        resourseId: this.$route.query.resourceId,
        token:this.token,
      }).then(res => {
        if(res.code == 10000){
          this.newsList = res.data.data
        }
      })
    },
    // 发送按钮
    sendClick(){
      this.saveTrainCourseNews()
    },
    saveTrainCourseNews(){
      this.$api.saveTrainCourseNews({
        resourcePointTime:this.newsList.resourcePointTime,
        newsContent:this.newinput,
        courseId: this.$route.query.courseId,
        resourseId: this.$route.query.resourceId,
        token:this.token
      }).then(res => {
        if(res.code == 10000){
          this.newinput = ''
          this.$message.success('发送成功')
          this.getTrainCourseNewsList()
        }
      })
    },
    // 课程详情
    getCourseDetailApi() {
      this.$api
        .getCourseDetailApi({
          courseId: this.$route.query.courseId,
          activityId: "",
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.detail = res.data.detail;
          }
        });
    },
    videoClick(chapterName,id){
      this.menuIndex = id    
      this.getMyCourseResource(id)
    },
    // 获取章节信息
    getFrontCourseChapterApi() {
      this.$api
        .getFrontCourseChapterApi({
          courseId: this.$route.query.courseId,
          activityId: this.$route.query.activityId,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.chapterList = res.data;
           this.menuIndex =  this.$route.query.resourceId,       
           this.getMyCourseResource()
          }
        });
    },
    getMyCourseResource(id) {
       this.$api
        .getMyCourseResource({
          resourseId:id ? id : this.$route.query.resourceId ,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.palyList = res.data.playList;
            this.videoList = res.data;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-box {
  background-color: #000;
  width: 100%;
  height: calc(100vh - 71px); //若页面占不满时加
  .videotop {
    background: #353235;
    padding: 17px;
    width: 100%;
    color: #9c999c;
    font-size: 18px;
    height: 70px;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
  .videotop-left {
    display: flex;
    align-items: center;
  }
  .videotit {
    margin-left: 20px;
    color: #9b989b;
  }
  .white {
    color: #fff;
  }
  .videotop .iconfont {
    font-size: 24px;
  }
  .cursor {
    cursor: pointer;
  }
  .playlist {
    position: absolute;
    top: 140px;
    left: 0;
    bottom: 0;
    width: 350px;
    font-size: 14px;
    background-color: #1a1a1a;
    color: #666;
    z-index: 2001;
    .container1 {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 20px 0 20px 20px;
        .course-name {
            font-size: 16px;
            color: #999;
            margin: 0 -15px;
            cursor: pointer;
        }
        .course-name span{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
            vertical-align: middle;
            max-width: 290px;
        }
    }
    .container1 ul.chapters {
        margin-top: 10px;
    }
    .chapters li {
        text-align: left;
    }
    .container1 ul.chapters li .sub-title {
        line-height: 30px;
        font-size: 15px;
        cursor: pointer;
    }
    .container1 ul.chapters li .task-info {
        line-height: 30px;
        // height: 30px;
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-left: 10px;
        padding-left: 25px;
        padding-right: 30px;
        position: relative;
        cursor: pointer;
        padding-left: 20px;
    }
    .container1 ul.chapters li .task-info .task-inner {
        display: inline-block;
        max-width: 100%;
        clear: both;
    }
    .container1 ul.chapters li .task-info .task-inner .task-name {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        vertical-align: middle;
        padding:0 3px;
    }
    .task-name a {
        color: #666;
    }
    .container1 ul.chapters li .task-info .task-suffix {
        display: inline-block;
        font-size: 12px;
        }
     .active {
        color: #18cca7;
      }   
    }
   .study-body {
    position: absolute;
    top: 135px;
    bottom: 0;
    left: 0;
    right: 0;
    // z-index: 2000;
     .ml{
        right: 0px;
      }
      
    }
    .iconote {
        right: 0;
        width: auto;
        z-index: 111;
        position: absolute;
        top: 30%;
        z-index: 2000;
    }
    .iconotebag {
        padding: 5px 12px;
        color: #fff;
        text-align: center;
        border-radius: 3px 0 0 3px;
        margin-top: 10px;
        cursor: pointer;
        background-color: #3ce4bc;
    }
    .iconotebag i {
        font-size: 26px;
    }
    .answer {
        position: absolute;
        width: 300px;
        height: 300px;
        background: #fff;
        z-index: 1;
        right: 60px;
        top: -35px;
        padding: 5px;
    }
    .answer::v-deep .el-textarea__inner{
        padding: 15px;
        width: 100%;
        height: 230px;
        border: none;
        background: #f0f0f0;
        font-family: 微软雅黑;
        resize: none;
    }
    .elowbut {
        margin-top: 10px;
        padding: 5px;
        text-align: right;
    }
    .editornote {
        position: absolute;
        width: 400px;
        background: #fff;
        z-index: 1;
        right: 55px;
        top: -35px
    }
    .chat-ctn {
        width: 300px;
        position: absolute;
        right: 0;
        bottom: 0;
        top: 135px;
        background: #fff;
        z-index: 999;
        .app-root{
            padding-top: 41px;
            box-sizing: border-box;
            height: 100%;
            position: relative;
            .tab-wrap {
                position: absolute;
                width: 100%;
                top: 0;
                .im-tab-hd--bottom {
                    display: flex;
                    border-bottom: 1px solid #2b2b2b;
                    line-height: 40px;

                }
                .im-tab-hd--bottom a{
                    color: #3ce4bc;
                    font-size: 14px;
                    flex: 1;
                    float: left;
                    text-align: center;
                    position: relative;
                    cursor: pointer;
                }
                .im-tab-hd {
                    font-size: 16px;
                    background: #333;
                }
            }
            .app-main{
              height: 100%;
              position: relative;
              background-color: #333;
              .main-ctn-wrap {
                  height: 100%;
                  box-sizing: border-box;
                  position: relative;
                  overflow: auto;
                  .chat-wrap {
                    width: 100%;
                    padding-bottom: 210px;
                    box-sizing: border-box;
                    .chat-list {
                        font-size: 12px;
                        position: absolute;
                        overflow-y: auto;
                        overflow-x: hidden;
                        padding-top: 4px;
                        bottom: 180px;
                        top: 0;
                        width: 100%;
                        box-sizing: border-box;
                        .chat-item-msg {
                          display: flex;
                          -webkit-box-orient: vertical;
                          width: 100%;
                          position: relative;
                          color: #fff;
                          padding: 10px 5px 10px;
                          margin-top: 5px;
                          border-bottom: 1px dashed #666;
                      }
                      .chat-item-msg img {
                          width: 30px;
                          height: 30px;
                          margin-right: 5px;
                          border: 1px solid #575757;
                          border-radius: 50%;
                          background: url(../.././assets/images/onlinecourses/avatar.png);
                          background-size: 30px 30px;
                      }
                      .chat-flex{
                          display: flex;
                          flex-direction: column;
                          flex: 1;
                          .chat-tit {
                            color: #858684;
                        }
                      }
                    }
                    .text-editor {
                        background: #333;
                        border-top: 1px solid #2b2b2b;
                        color: #ccc;
                        width: 300px;
                        height: 180px;
                        position: fixed;
                        bottom: 0;
                        .text-right {
                          text-align: right;
                      }
                      .pdr {
                          padding-right: 10px;
                      }
                    }
                  }
                }
              }
            }
        }
    }
  .page-b {
    width: 100%;
    height: calc(100vh - 140px);
  }
  .video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
.share::v-deep .el-button--text {
  color: #9c999c;
  font-size: 18px;
}
.white::v-deep .el-button--text {
  color: #fff;
  font-size: 18px;
}
.app-root::v-deep .el-divider{
  height: 3px;
  margin: 0px;
  background-color: #3ce4bc;
}
.text-editor::v-deep .el-textarea__inner{
  height: 125px;
  margin-bottom: 10px;
  padding: 5px 8px 0;
  border-radius: 1px;
  background: #242424;
  color: #ccc;
  overflow-y: auto;
  text-align: left;
  border: 0px solid #DCDFE6;
}
</style>